// <!-- rotating values -->
// <div class="bx-cont">
//   <ul id="bxslider">
//     <li>What do you love about school?</li>
//     <li>Insert your quotes here.</li>
//     <li>Press ENTER to add a new quote.</li>
//   </ul>
// </div>

// // ------------------------------ Bx Slider
// $('#bxslider').bxSlider({
// 	mode: 'fade',
// 	auto: true,
// 	pager: true,
// 	controls: false,
// 	// slideWidth: 500,
// 	autoControls: false,
// 	pause: 7000
// });

#testimonials {
  width: 100%;
  z-index: 10;
  font-family: 'Oooh Baby', 'Brush Script MT', cursive;
  position: relative;
  top: auto;
  background: transparent;
  padding: 0 0 50px 0;

  @media only screen and (max-width: 450px) {
    padding: 0px 0 20px 0;
  }

  .wrapper {}
}

// BXSLIDER

.bx-cont {
  margin: 0 auto;
  max-width: 1325px;
  width: 90%;
  text-align: center;
  color: #fff;


  ul#bxslider {

    li {
      padding: 0;
      background: none;
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
      color: #FFF;
      font-size: 54px;
      line-height: 1.3;

      @media only screen and (max-width: 1300px) {
        font-size: 4vw;
      }

      @media only screen and (max-width:700px) {
        font-size: 27px;
      }

      strong {
        font-weight: normal;
        display: block;
        font-size: 30px;
        margin: 15px 0 0 0;

        @media only screen and (max-width: 990px) {
          font-size: 3vw;
        }

        @media only screen and (max-width:700px) {
          font-size: 23px;
        }
      }
    }
  }
}

// BXSLIDER CONTROLS - INDICATORS

// .bx-controls{ display: block; text-align: center; margin: 30px auto; border-radius: 50%;

//   .bx-pager-item { display: inline-block; height: 20px; width: 20px; margin: 0 8px; border-radius: 50%;

//     .bx-pager-link { height: 20px; width: 20px; background: #947cb3; overflow: hidden;color: transparent; display: inline-block; margin: 0 auto; border-radius: 50%;}

//       .active { background: #344055;}
//   }
// }

// BXSLIDER CONTROLS - ARROWS

// .bx-prev {position: absolute; color: transparent; left: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-left.png) no-repeat center; background-size: contain;}

// .bx-next {position: absolute; color: transparent; right: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-right.png) no-repeat center; background-size: contain;}


// for c_import in the CMS

// [bx-container] {margin: 50px auto; left: 0; right: 0; top: 0;}
// [bx-container]  li {border: 5px dashed #fff; background: #222!important; padding: 10px; color: #fff!important;}