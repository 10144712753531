// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_1084 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;

  height: 80vh;

  &:before {
    @include before(559px, 541px);
    background: url(/i/design/slider-watermark.png) no-repeat top left;
    top: 200px;
    left: 0;
    pointer-events: none;

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;
}

.backstretch {
  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(100%, 368px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 427px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 50px;
}