// Global Variables
@import 'c_mixins';
@import url(/inc/font/stylesheet.css);


// CMS UI Styles
@import 'c_ui_styles';

// reset
@import 'c_reset';

// accessibility 
@import 'c_accessibility';

@import 'c_site';
@import 'c_default';

// --- optionals

// @import 'c_pop-up_vid';
@import 'c_bx-slider';
// @import 'c_social';
// @import 'c_animated';
// @import 'c_curriculum';

@import 'c_insidepages';
@import 'c_slider';
@import 'c_cmsstyles';

// Mobile / Responsive Imports
@import 'c_meanmenu';
@import 'c_respond';

//Quick links widget
@import 'c_quicklinks';

