// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//

$slideOut-panel-bg: hotpink;
$link-bg-colour: #222;
$link-bg-colour-hover: rgba(255,255,255,.1);
$links-colour: #fff;

$slide-click-colour: #fff;

$mobile-tab-color: hotpink;

/* Full container */
#slideOut { position: fixed; width: auto; height: auto; min-height: 250px; top: calc(50% - 200px); margin: 0!important; left: -250px; background: $slideOut-panel-bg; padding: 0px; z-index: 999; }

/* Click to slide */
#slideClick { float: right; height: 100%; width: 70px; cursor: pointer; position: relative;

 span{
		width: 160px;
		height: 70px;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 25px;
		color: $slide-click-colour;
		white-space: nowrap;
		transform: rotate(-90deg);
		margin-left: -68px;
		margin-top: -37px;

		// &:before{
		// 	content: "";
		// 	width: 7px;
		// 	height: 12px;
		// 	background: url(/i/design/arrow-open-close.png) no-repeat center center;
		// 	position: absolute;
		// 	left: 48%;
		// 	top: 57%;
		// 	transform: rotate(90deg);
		// }
	}
}

/* Change the arrow  */
.open span::before{transform: rotate(-90deg) !important;}
.flip {display: none;}

/* Content links */
#slideContent {	width: auto; height: 100%; padding: 0; margin: 0; float: left; color: #EFEFEF; text-align: center; }

/* Box with links  */
ul.quick-links{ width: 240px; display: block; text-align: center; overflow: hidden;

	li{ background: none; margin: 0 0 5px 0; padding: 0; display: block;

		a{ background: $link-bg-colour url(/i/design/arrow-quick-links.png) no-repeat left 15px center; color: $links-colour; display: block;	text-align: left; transition: .3s;

			font-size: 18px;
			padding: 8px 10px 8px 45px;

			&:hover{ background-color: $link-bg-colour-hover; text-decoration: none; color: #fff; transition: .5s; }
		}
	}
}

@media only screen and (min-width: 990px) {

	#slideContent{display: table !important;}
	ul.quick-links {vertical-align: middle; display: table-cell; }
}

@media only screen and (max-width: 990px) {

	#slideOut{ position: relative; top: 44px; min-height: 0px; left: 0; padding: 0px 3% 0 2%; overflow: visible; height: auto !important; box-shadow: 0 0 0 1px rgba(0,0,0,0.08); border-radius: 0; margin: 0!important; background: $mobile-tab-color;	}

	#slideClick{ display: none;
		span{ transform: rotate(0); position: absolute; top: 0; left: 0; width: auto; height: auto; margin-left: 0; margin-top: 0; position: relative; }
	}

	#slideContent{ float: none; }

	ul.quick-links{ width: 100%;
		li {
				a { padding: 6px 0 6px 0; text-align: center; background-position: 8% 10px; font-size: 15px;
			}
		}
	}

	.flip{ z-index: 800; margin: 0; padding: 5px 0; cursor: pointer; font-size: 13px; color: #fff; display: block;

		img{display: block; margin: 0 auto; transform: rotate(90deg);}
	}

	.open img{transform: rotate(-90deg);}
}
