$nav-link-bg-hov: #2B3892;
$nav-dropdown-hov: darken($nav-link-bg-hov, 4%);

// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//

nav#navholder {
    z-index: 400;
    @extend .clearfix;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 60px;
    pointer-events: none;

    @media only screen and (max-width: 1200px) {
        top: 30px;
    }

    >.wrapper {
        // width: 990px;
        max-width: 90%;
        margin: 0 auto;
    }
}

// ----------------------------------- MAIN NAVIGATION

ul.nav {
    display: table;
    width: fit-content;
    margin: 0;
    list-style: none;
    text-align: center;
    float: right;
    pointer-events: visible;

    // Top Level List
    li {
        margin: 0;
        background: none;
        position: relative;
        display: inline-block;
        text-align: center;
        white-space: nowrap;
        padding: 0;

        a {
            height: 50px;
            line-height: 50px;
            display: block;
            text-align: center;
            font-size: 18px;
            padding: 0 12px;
            color: #fff;
            font-weight: 500;

            @media only screen and (max-width: 1800px) {
                padding: 0 10px;
            }

            @media only screen and (max-width: 1500px) {
                font-size: 17px;
                padding: 0 8px;
            }

            @media only screen and (max-width: 1350px) {
                font-size: 16px;
                padding: 0 6px;
            }
        }
    }

    >li:hover>a,
    >li>a:hover {
        color: #fff;
        text-decoration: none;
        // box-shadow: 0 2px 0 0 #fff;

        &::before {
            position: absolute;
            content: "";
            background: #fff;
            height: 1px;
            width: 58px;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    // 2nd Level List
    li {
        ul {
            position: absolute;
            left: -999em;
            margin: 0;
            padding: 5px 20px 20px 20px;
            background: none;
            opacity: 0;
            visibility: hidden;
            transition: (all 0.3s ease-in-out);
        }

        &:hover ul {
            left: auto;
            margin-left: -20px;
            opacity: 1;
            visibility: visible;
            transition: (all 0.3s ease-in-out);
            z-index: 99;
        }

        &:active ul {
            transition: (opacity 0.1s ease-in-out);
        }
    }

    // 2nd Level List Items
    li li {
        float: none;
        display: block;
        padding: 0;
        height: auto;
        line-height: 35px;

        a {
            background: $nav-link-bg-hov;
            height: auto;
            line-height: 1.1;
            white-space: pre-wrap;
            padding: 15px 12px;
            min-width: 200px;
            float: none;
            top: auto;
            display: block;
            position: static;
            text-align: left;
            text-decoration: none;
            font-size: 14px;
            text-transform: none;
            border-bottom: 1px solid rgba(255, 255, 255, .2);
        }

        &:hover,
        &:hover>a,
        a:hover,
        a:focus {
            background: $nav-dropdown-hov;
        }
    }

    // Prepend 'more' arrow to parent list items
    li li a.parent:after {
        margin: -1px 0 0 10px;
        content: url('/i/design/lite/nav-right-arrow-white.png');
        opacity: 1;
        line-height: 1;
    }

    // 3rd Level List
    ul ul {
        z-index: 505;
        padding-top: 0;
    }

    li:hover ul ul {
        left: 100%;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: (all 0.3s ease-in-out);
        pointer-events: none;
        user-select: none;
    }

    ul li:hover ul {
        left: 100%;
        top: 0;
        opacity: 1;
        visibility: visible;
        transition: (all 0.3s ease-in-out);
        pointer-events: all;
        user-select: all;
    }
}

// ul.nav li:last-of-type ul li:hover ul,
// ul.nav li:nth-last-of-type(2) ul li:hover ul,
// ul.nav li:nth-last-of-type(3) ul li:hover ul {
// 	left: -180px;
// 	width: 200px;
// 	padding: 0;
// }

// -----------------------------------//
// NAVIGATION DOCKED ON SCROLL
// -----------------------------------//

#navholder.sticky.fixed {
    position: fixed;
    animation: move-down 1s ease forwards;
    background: rgba(#000, .8);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;

    ul.nav {
        text-align: center;
    }

    ul.nav li a {
        height: 40px;
        line-height: 40px;
    }

    @keyframes move-down {
        from {
            top: -50px;
            opacity: 0;
        }

        to {
            top: 0;
            opacity: 1;
        }
    }
}